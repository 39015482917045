import React from 'react';
import { Grid } from "@mui/material";
import { ImagePath } from "../../templates/style";
import MenuListItem from "../utils/MenuListItem";
import isAuthorized from "../../security/isAuthorized";

const menuItems = [
    // cuts should be independent of data manager
    { link: "/data_manager/cuts", image: ImagePath.iconGestionDonnee, title: "Gestion de données" },
    { auth: { module: "martelage", permission: "R" }, link: "/hammering", image: ImagePath.iconMartelage, title: "Martelage" },
    { auth: { module: "cubage", permission: "R" }, link: "/measurement", image: ImagePath.iconCubage, title: "Cubage" },
    { auth: { module: "lot", permission: "R" }, link: "/lot", image: ImagePath.iconLotsCreation, title: "Création de lots" },
    // missing products
    { auth: { module: "carte", permission: "R" }, link: "/map", image: ImagePath.iconGestionPiles, title: "Gestion des piles (carte)" },
    { link: "/export/cuts", image: ImagePath.iconMenuExport, title: "Export" },
    { link: "/parameters/users", image: ImagePath.iconReglages, title: "Réglages" },
];

const AppDrawerMenuList = ({ drawerExpanded }) => {
    return (
        <div>
            <Grid container direction="column" alignItems="start" style={{ width: "100%" }}>
                {menuItems.map((item, index) => (
                    (!item.auth || isAuthorized(item.auth.module, item.auth.permission)) && (
                        <Grid item xs={1} key={index} style={{ width: "100%" }}>
                            <MenuListItem
                                link={item.link}
                                image={item.image}
                                title={item.title}
                                drawerExpanded={drawerExpanded}
                            />
                        </Grid>
                    )
                ))}
            </Grid>
        </div>
    )
};

export default AppDrawerMenuList;