import React, { useState, useEffect } from "react";
import { Box, Drawer, IconButton } from "@mui/material";
import { Color, ImagePath, Transition } from "../../templates/style";
import { AppDrawerMenuList } from "./";
import MenuIcon from '@mui/icons-material/Menu';

const AppDrawer = ({ hidden }) => {

    const [drawerExpanded, setDrawerExpanded] = useState(false || localStorage.getItem('appDrawerExpanded') === "true");

    const toggleDrawerSize = () => {
        const newExpandedState = !drawerExpanded;
        setDrawerExpanded(newExpandedState);
        localStorage.setItem('appDrawerExpanded', newExpandedState.toString());

        document.documentElement.style.setProperty('--drawer-width', newExpandedState ? '300px' : '72px');
    }

    useEffect(() => {
        document.documentElement.style.setProperty('--drawer-width', drawerExpanded ? '300px' : '72px');

        return () => {
            document.documentElement.style.removeProperty('--drawer-width');
        };
    }, [drawerExpanded]);

    const drawerWidth = drawerExpanded ? 300 : 72

    return <div>
        <style>
            {`@media print {.no-print{display: none;}}`}
        </style>
        {hidden ? (
            <Drawer
                className={"no-print"}
                variant="permanent"
                sx={{
                    display: { xs: "none", sm: "block" },
                    width: 300,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        borderColor: "transparent",
                        borderWidth: 0,
                        width: 300,
                        boxSizing: "border-box",
                        bgcolor: "transparent",
                    },
                }}
            >
                <Box
                    sx={{
                        backgroundColor: Color.primary,
                        maxWidth: "100%",
                        padding: "8px 12px",
                        minHeight: 64,
                        maxHeight: 64,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <img
                        src={ImagePath.logoHeadBar}
                        style={{ height: 48 }}
                    />
                </Box>
            </Drawer>
        ) : (
            <Drawer
                className={"no-print"}
                variant="permanent"
                sx={{
                    display: { xs: "none", sm: "block" },
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        borderColor: "transparent",
                        borderWidth: 0,
                        width: drawerWidth,
                        boxSizing: "border-box",
                        bgcolor: Color.secondary,
                        overflowX: "hidden",
                        transition: `width ${Transition.drawer}`,
                    },
                }}
            >
                <Box
                    sx={{
                        cursor: "pointer",
                        backgroundColor: Color.primary,
                        maxWidth: "100%",
                        padding: "8px 12px",
                        minHeight: 64,
                        maxHeight: 64,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                    onClick={toggleDrawerSize}
                >
                    <img
                        src={drawerExpanded ? ImagePath.logoHeadBar : ImagePath.logoIcon}
                        style={{ height: 48 }}
                    />
                    {drawerExpanded ?
                        <IconButton aria-label="Menu" type="reset" sx={{
                            color: Color.secondary,
                            "&:hover": { bgcolor: Color.primary_hover },
                        }} >
                            <MenuIcon sx={{ color: Color.secondary }} fontSize="large" />
                        </IconButton> :
                        null}
                </Box>
                <Box
                    sx={{
                        bgcolor: "transparent",
                        height: "100%",
                        maxWidth: "100%",
                        boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <AppDrawerMenuList drawerExpanded={drawerExpanded} />
                </Box>
            </Drawer>
        )}
    </div>
}

export default AppDrawer;